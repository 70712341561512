import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect, useState } from 'react'
import './App.css'
import About from './components/about/About'
import ContactUs from './components/contact-us/ContactUs'
import Footer from './components/footer/Footer'
import Hero from './components/hero/Hero'
import Navbar from './components/navbar/Navbar'
import OurDirections from './components/our-directions/OurDirections'
import StudentLife from './components/student-life/StudentLife'

function App() {
	useEffect(() => {
		AOS.init({
			// disable: 'phone',
			duration: 1500,
			delay: 300,
			easing: 'ease-out-cubic',
		})
	}, [])

	const [navBg, setNavBg] = useState(false)

	const changeNavBg = () => {
		window.scrollY >= 80 ? setNavBg(true) : setNavBg(false)
	}

	useEffect(() => {
		window.addEventListener('scroll', changeNavBg)
		return () => {
			window.removeEventListener('scroll', changeNavBg)
		}
	}, [])
	return (
		<div className='App' onScroll={changeNavBg}>
			<Navbar isBlur={navBg} />
			<Hero />
			<About />
			<OurDirections />
			<StudentLife />
			<div  id='contact-us'><ContactUs /></div>			
			<Footer />
		</div>
	)
}

export default App
