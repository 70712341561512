import React, { useState } from "react";
import i18n from "../../i18n";
import "./LanguageSelector.css";

const LanguageSelector = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [open, setOpen] = useState(false);

  const chooseLanguage = (value) => {
    setOpen(false);
    i18n.changeLanguage(value);
    setSelectedLanguage(value);
  };
  return (
    <div className="language-select-btn" onClick={() => setOpen(!open)}>
      <img src={selectedLanguage==='uz'?"./img/uzb.svg":"./img/rus.svg"} width={24} height={24} />

      <span className="ms-2 text-white">{selectedLanguage==='uz'?'Uz':'Ру'}</span>

      {open && (
        <div className="language-selector">
          <div
            className="language-select-item"
            onClick={() => chooseLanguage("uz")}
          >
            <img
              className="language-select-item-flag"
              src="./img/uzb.svg"
              width={24}
              height={24}
            />
            Uz
          </div>
          <div
            className="language-select-item"
            onClick={() => chooseLanguage("ru")}
          >
            <img
              className="language-select-item-flag"
              src="./img/rus.svg"
              width={24}
              height={24}
            />
            Ру
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
