import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationsInGerman from "../locales/ru/translation.json";
import translationsInItalian from "../locales/uz/translation.json";

// the translations
const resources = {
  ru: {
    translation: translationsInGerman,
  },
  uz: {
    translation: translationsInItalian,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "uz",
  debug: true,
  fallbackLng: "uz",
  interpolation: {
    escapeValue: false,
  },
  ns: "translation",
  defaultNS: "translation",
});

export default i18n;
