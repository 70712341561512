import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ContactUs from '../contact-us/ContactUs'
import LanguageSelector from '../language-selector/LanguageSelector'
import './Navbar.css'

const navbarList = [
	{
		name: 'About Us',
		hash: '#about-us',
		active: '',
	},
	{
		name: 'Our direction',
		hash: '#our-directions',
		active: '',
	},
	{
		name: 'FAQ',
		hash: '#faq',
		active: '',
	},
	{
		name: 'Contact us',
		hash: '#contact-us',
		active: '',
	},
]

const Navbar = ({ isBlur }) => {
	const { t } = useTranslation()
	const [navbar, setNavbar] = useState(navbarList)

	const activeItem = hash => {
		var newNavbarList = []

		navbarList.map(item => {
			if (item.hash === hash) {
				newNavbarList.push({ ...item, active: 'active' })
			} else {
				newNavbarList.push({ ...item, active: '' })
			}
		})

		setNavbar(newNavbarList)
	}

	return (
		<>
			<nav className='navbar-content'>
				<div
					className='navbar navbar-expand-xxl navbar-fixed-top'
					style={{ background: `${isBlur ? '#0058bd71' : '#0057BD'}` }}
				>
					<div className='container'>
						<a className='navbar-brand' href='#'>
							<img
								src='./img/logo.svg'
								alt='Logo'
								width='200'
								height='56'
								className='d-inline-block align-text-top'
							/>
						</a>
						<button
							className='navbar-toggler'
							type='button'
							data-bs-toggle='collapse'
							data-bs-target='#navbarText'
							aria-controls='navbarText'
							aria-expanded='false'
							aria-label='Toggle navigation'
						>
							<span className='navbar-toggler-icon'></span>
						</button>
						<div className='collapse navbar-collapse' id='navbarText'>
							<ul className='navbar-nav mx-auto mb-2 mb-lg-0'>
								{navbar?.map(item => (
									<li className='nav-item' key={item.hash}>
										<a
											onClick={() => activeItem(item?.hash)}
											className={`nav-link ${item?.active}`}
											aria-current='page'
											href={item?.hash}
										>
											{t(item?.name)}
										</a>
									</li>
								))}
							</ul>
							<div className='language-btn'>
								<LanguageSelector />
							</div>
							<button
								className='register-btn'
								type='button'
								data-bs-toggle='modal'
								data-bs-target='#exampleModal'
							>
								{t('Register')}
							</button>
						</div>
					</div>
				</div>
			</nav>
			<div
				className='modal fade'
				id='exampleModal'
				tabIndex='-1'
				aria-labelledby='exampleModalLabel'
				aria-hidden='true'
			>
				<div className='modal-dialog modal-xl'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h1 className='modal-title fs-5' id='exampleModalLabel'>
								{t('Register')}
							</h1>
							<button
								type='button'
								className='btn-close'
								data-bs-dismiss='modal'
								aria-label='Close'
							></button>
						</div>
						<div className='modal-body'>
							<ContactUs />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Navbar
