import React from 'react'
import './Gallery.css'

const Gallery = () => {
	return (
		<div class='container-gallery'>
			<div class='gallery-container w-2 h-2'>
				<div class='gallery-item'>
					<div class='image'>
						<img
							src='./img/student-life1.svg'
							alt='nature'
							data-aos='fade-right'
						/>
					</div>
				</div>
			</div>

			<div class='gallery-container w-2 h-4'>
				<div class='gallery-item'>
					<div class='image'>
						<img
							src='./img/student-life6.svg'
							alt='nature'
							data-aos='fade-down'
						/>
					</div>
				</div>
			</div>
			<div class='gallery-container w-2 h-3'>
				<div class='gallery-item'>
					<div class='image'>
						<img
							src='./img/student-life5.svg'
							alt='nature'
							data-aos='fade-left'
						/>
					</div>
				</div>
			</div>
			<div class='gallery-container w-2 h-4'>
				<div class='gallery-item'>
					<div class='image'>
						<img
							src='./img/student-life4.svg'
							alt='nature'
							data-aos='fade-up-right'
						/>
					</div>
				</div>
			</div>
			<div class='gallery-container w-2 h-3'>
				<div class='gallery-item'>
					<div class='image'>
						<img
							src='./img/student-life2.svg'
							alt='nature'
							data-aos='fade-up'
						/>
					</div>
				</div>
			</div>
			<div class='gallery-container w-2 h-2'>
				<div class='gallery-item'>
					<div class='image'>
						<img
							src='./img/student-life3.svg'
							alt='nature'
							data-aos='fade-up-left'
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Gallery
