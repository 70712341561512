import React from 'react'
import { useTranslation } from 'react-i18next'
import './About.css'

const About = () => {
	const { t } = useTranslation()
	return (
		<div className='container my-5'>
			<div id='about-us' className='about-section'>
				<div className='about-box' data-aos='fade-up-right'>
					<h2>{t('About Us')}</h2>
					<p>{t('Our_training_course')}</p>
				</div>
				<div className='about-section-img' data-aos='fade-up-left'>
					<img src='./img/about-img.svg' alt='' srcSet='' />
				</div>
			</div>
			<div className='license'>
				<div className='license-box' data-aos='fade-up'>
					<h2>{t('License')} </h2>
					<p data-aos='zoom-in-right'>{t('We_provide_an_official')}</p>
					<ul>
						<li data-aos='zoom-in-right'>
							<img src='./img/success.svg' alt='' srcSet='' />
							<span>{t('Confirms_completion_of_the_course')}</span>
						</li>
						<li data-aos='zoom-in-right'>
							<img src='./img/success.svg' alt='' srcSet='' />
							<span>{t('Official_document')}</span>
						</li>
						<li data-aos='zoom-in-right'>
							<img src='./img/success.svg' alt='' srcSet='' />
							<span>{t('Has_legal_force')}</span>
						</li>
						<li data-aos='zoom-in-right'>
							<img src='./img/success.svg' alt='' srcSet='' />
							<span>{t('Career_Opportunities')}</span>
						</li>
					</ul>
				</div>
				<div className='license-img'>
					<img src='./img/litsenziya1.svg' data-aos='zoom-in' alt='' />
					<img src='./img/litsenziya2.svg' data-aos='zoom-in' alt='' />
				</div>
			</div>
		</div>
	)
}

export default About
