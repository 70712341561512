import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '../card/Card'
import './OurDirections.css'

const OurDirections = () => {
	const { t } = useTranslation()

	const cardData = [
		{
			title: t('Nursing_course'),
			desc: t('Our_nurse_training'),
			img: './img/card3.svg',
			id: 1,
			aos: 'fade-right',
		},
		{
			title: t('Pharmacy_course'),
			desc: t('Our_Pharmacy_course'),
			img: './img/card2.svg',
			id: 2,
			aos: 'fade-up',
		},
		{
			title: t('Paramedic_obstetrician_course'),
			desc: t('Our_Nurse_Obstetrician'),
			img: './img/card1.svg',
			id: 3,
			aos: 'fade-left',
		},
	]
	return (
		<div className='our-direction' id='our-directions'>
			<div className='container'>
				<div className='our-direction-box'>
					<h2>{t('Our direction')}</h2>
					<p>{t('Our_college_diplomas')}</p>
					<div className='row mt-5 row-gap-4'>
						{cardData?.map(item => (
							<div className='col-xl-4 col-lg-6' key={item.id}>
								<Card key={item.id} data={item} />
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default OurDirections
