import React from 'react'
import { useTranslation } from 'react-i18next'
import './Hero.css'

const Hero = () => {
	const { t } = useTranslation()
	return (
		<div className='hero'>
			<img src='./img/hero-bgc.svg' className='hero-img w-100 h-100' alt='' />
			<div className='hero-section'>
				<div className='container h-100'>
					<div className='hero-section-content'>
						<div className='hero-section-box' data-aos='fade-right'>
							<h2 className='text-white'>
								{t('The_future_of_medicine_begins_with_you')}
							</h2>
							<p>{t('Your_professional_career')}</p>
							<button
								className='hero-register-btn'
								type='button'
								data-bs-toggle='modal'
								data-bs-target='#exampleModal'
							>
								{t('Register')}
							</button>
						</div>
						<div className='hero-section-img' data-aos='fade-left'>
							<img src='./img/hero-img.png' alt=''></img>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Hero
