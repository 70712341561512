import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./ContactUs.css";

const ContactUs = () => {
  const { t } = useTranslation();
  const telegram_bot_token = "7543150406:AAHculFUd0QZye1S0YUMrJKCl-DRB2ZCUEM";
  const telegram_chat_id = "759672792";
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessages] = useState("");

  const sendMessage = () => {
    const telegram_message = `Name: ${name}\nPhonel: ${phone}\nMessage: ${message}`;

    fetch(`https://api.telegram.org/bot${telegram_bot_token}/sendMessage`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chat_id: telegram_chat_id,
        text: telegram_message,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          setName("");
          setPhone("");
          setMessages("");
        }
      })
      .catch((error) => {
        console.error("Error sending message:", error);
      });
  };

  return (
    <div className="container">
      <div className="contact-us">
        <div className="row">
          <div className="col-lg-5 col-12">
            <div className="contact-us-card">
              <h4 data-aos="fade-right">{t("Our contacts")}</h4>
              <p data-aos="fade-right">{t("We will answer your questions")}</p>
              <ul>
                <li data-aos="fade-right">
                  <img src="./img/bxs_phone-call.svg" alt="" />
                  <span>+998 75 200 03 10</span>
                </li>
                <li data-aos="fade-right">
                  <img src="./img/ic_sharp-email.svg" alt="" />
                  <span>txttuz@gmail.com</span>
                </li>
                <li data-aos="fade-right">
                  <img src="./img/carbon_location-filled.svg" alt="" />
                  <span>
                    {t(
                      "Tashkent region, Chirchik city, MFP Adolat, avenue 50th anniversary of Chirchik."
                    )}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-7 col-12 px-5 py-4">
            <h2 data-aos="fade-left">{t("Contact us")}</h2>
            <div className="contact-us-box">
              <input
                type="text"
                placeholder={t("I.F.O")}
                value={name}
                onChange={(e) => setName(e.target.value)}
                data-aos="fade-left"
              />
              <input
                type="number"
                placeholder={t("Phone number")}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                data-aos="fade-left"
              />
              <textarea
                name="moree"
                rows="3"
                placeholder={t("learn more")}
                maxLength={300}
                value={message}
                onChange={(e) => setMessages(e.target.value)}
                data-aos="fade-left"
              ></textarea>
              <button
                className="contact-us-btn"
                disabled={
                  name.length < 1 || phone.length < 1 || message.length < 1
                }
                onClick={sendMessage}
                data-aos="fade-left"
              >
                {t("Send message")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
