import Collapse from 'rc-collapse'
import 'rc-collapse/assets/index.css'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Gallery from '../gallery/Gallery'
import './StudentLife.css'

const StudentLife = () => {
	var Panel = Collapse.Panel
	const { t } = useTranslation()
	return (
		<div className='student-life'>
			<div className='container'>
				<h2>{t('student_life')}</h2>

				<Gallery />

				<div id='faq' className='mt-5'>
					<h2>
						{t('FAQ')} - {t('Questions_and_answers')}
					</h2>
					<Collapse accordion={true} data-aos='fade-right'>
						<Panel
							header={t('What documents do I need for technical school?')}
							headerclassName='my-header-class'
						>
							<p>
								{t('Required_documents')}
								<br />
								<ul className=''>
									<li className='list-item'>{t('Passport_and_permanent')}</li>
									<li className='list-item'>
										{t('College')} <code className='mx-1'>{t('diploma')}</code>
										{t('or')}
										<code className='mx-1'>11-{t('class')}</code>
										{t('certificate')}.
									</li>
									<li className='list-item'>
										<span className='fs-5'>
											3<sup>x</sup>4
										</span>
										{t('picture - 6 pcs')}.
									</li>
									<li className='list-item'>{t('Copy of Zacks')}.</li>
								</ul>
							</p>
						</Panel>
						<Panel
							headerclassName='my-header-class'
							header={t('Is extramural training available?')}
						>
							<p>
								{t('Unfortunately, it is part-time')}
								<span>({t('Zaochnoe')})</span> {t('not available')}
							</p>
						</Panel>
						{/* <Panel
							headerclassName='my-header-class'
							header={t('What routes are available?')}
						>
							<p>
								{t('Our technician has two')}
								<br />
								<br />
								{t('if you choose the')}
								<br />
								<br />
								{t('Its a bonus if you')}
							</p>
						</Panel> */}
						<Panel headerclassName='my-header-class' header={t('Can I enter')}>
							<p>
								{t('If you graduate')}
								<code className='mx-1'>{t('without exam')}</code>{' '}
								{t('based on conversation')}
								<code className='mx-1'>
									{t('You will be accepted from the 2nd course.')}
								</code>
							</p>
						</Panel>
						<Panel
							headerclassName='my-header-class'
							header={t('Can the contract fee be paid in installments?')}
						>
							<p>
								{t('Of course the contract fee')}{' '}
								<code className='mx-1'>{t('to 4')}</code>
								{t('You can pay in installments')}
								<code className='mx-1'>{t('15 percent')}</code>
								{t('')}
							</p>
						</Panel>
					</Collapse>
				</div>
			</div>
		</div>
	)
}

export default StudentLife
