import React from 'react'
import './Card.css'
const Card = ({ data }) => {
	return (
		<div className='card'>
			<img src={data.img} alt='' data-aos={data.aos} />
			<h4 className='card-title' data-aos={data.aos}>
				{data.title}
			</h4>
			<p className='card-desc' data-aos={data.aos}>
				{data.desc}
			</p>
		</div>
	)
}

export default Card
